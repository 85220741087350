.info {
    position: relative;
    background-image: url('../../img/infoblock/infoblock_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 554px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.roundWhiteTop {
    position: absolute;
    top: -100px;
    right: 0;
}

.roundWhiteBottom {
    position: absolute;
    bottom: -130px;
    left: 0;
}

.infoContent {
    color: #fff;
    text-align: center;
}

.mainContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.textContainer {
    width: 100%;
    max-width: 300px;
    /* margin-left: 5rem; */
    /* text-align: center; */
}


/* .textContainer:first-child {
    margin: 0;
} */

.numbers {
    font-family: Catamaran, sans-serif;
}

@media only screen and (max-width: 768px) {
    .roundWhiteTop {
        display: none;
    }
    .roundWhiteBottom {
        display: none;
    }
    .mainContent {
        flex-direction: column;
    }
    .textContainer {
        margin-left: 0;
        margin-bottom: 1rem;
    }
    /* .textContainer:last-child {
        margin: 0; 
    } */
}