.header {
    position: fixed;
    z-index: 99;
    width: 100%;
    font-family: Catamaran, sans-serif;
    transition: all .25s ease-in-out;
}

.headerOnScroll {
    transition: all .25s ease-in-out;
    background-color: none;
}

.active {
    /* border-bottom: 1px solid rgb(232, 232, 232); */
    background-color: #fff;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    ;
    /* overflow-y: hidden; */
}

.nav {
    padding: .8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: relative; */
}

.logoContainer {
    cursor: pointer;
    transition: all .5s;
}

.logoContainer:hover {
    opacity: .65;
}

.logoContainer {
    width: 250px;
}

.logoImage {
    width: 100%;
}

.navItem {
    transition: all .5s;
    font-weight: 300;
    display: block;
    cursor: pointer;
    text-transform: capitalize;
    margin-left: 1.25rem;
    padding: .85rem 0 .5rem 0;
    border-bottom: 2px solid transparent;
    font-size: 1.125rem;
    font-family: Catamaran,sans-serif;
}

.navItem:first-child {
    margin-left: 0;
}

.navItem:hover {
    border-bottom: 2px solid black;
}

.selectContainer {
    margin: 0 1.5rem;
}

.btn {
    width: 150px;
}

.loginBtn {
    /* important is being used to override material ui style orders */
    background-color: rgb(40, 121, 255) !important;
    color: #fff !important;
    transition: all .5s !important;


}

.loginBtn:hover {
    background-color: rgb(46, 192, 221) !important;
}

.whatsAppBtn:hover {
    color: #4ced69 !important;
    fill: #4ced69 !important;
    background-color: #fff !important;
}

.mobile {
    display: none;
}

/* hamburger styling */

.menuBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 23px;
}

.menuBtnBurger {
    position: relative;
    width: 30px;
    height: 3px;
    background-color: black;
    border-radius: 5px;
    transition: all .25s ease-in-out;
}

.menuBtnBurger::before,
.menuBtnBurger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: black;
    border-radius: 5px;
    transition: all .25s ease-in-out;
}

.menuBtnBurger::before {
    transform: translateY(-8px);
}

.menuBtnBurger::after {
    transform: translateY(8px);
}

/* ANIMATION */

.menuBtn.open .menuBtnBurger {
    background-color: transparent
}

.menuBtn.open .menuBtnBurger::before {
    transform: rotate(45deg) translateY(0px);
}

.menuBtn.open .menuBtnBurger::after {
    transform: rotate(-45deg) translateY(0px)
}

/* mobile menu bar */

.mobileMenuContainer {
    transition: all .25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    padding: 1rem 0;
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 4rem;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    background-color: #fff;
    /* height: 100vh; */
}

.mobileMenu {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.mobileMenuContainer.menuOpen {
    opacity: 1;
    visibility: visible;
}

.headerWhite {
    background-color: white;
}

@media only screen and (max-width: 1200px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .navItem {
        margin: 0 0 .25rem 0;
        padding: 0 0 .5rem 0;
    }

    .navItem:hover {
        border-bottom: 2px solid rgb(46, 192, 221);
    }

    .loginBtn {
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .logoContainer {
        width: 190px;
    }

    .mobileMenuContainer {
        top: 3rem;
        /* height: 100vh; */
    }
}