.bg {
    height: 250px;
    cursor: pointer;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.5s;
}

.bg:hover {
    transform: scale(1.05);
    box-shadow: 0px 1px 16px 10px rgba(40, 121, 255, 0.1);

}

.headingContainer {
    display: flex;
    align-items: center;
}


.reviews {
    max-width: 500px;
    margin: auto;
}

.textContainer {
    max-width: 300px;
    width: 85%;
    margin: auto;
    text-align: center;
}

@media only screen and (max-width: 968px) {
    .bg {
        height: 200px;
        max-width: 300px;
        margin: auto;
    }


    .sellerReviews {
        text-align: center;
    }

    .headingContainer {
        justify-content: center;
    }

    .line {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .textContainer {
        width: 100%;
    }

}