.title {
    line-height: 1;
}

.image {
    border-radius: 30% 70% 42% 58% / 30% 21% 79% 70%;
    transform: scaleX(-1);
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 500px;
    background-image: url('../../img/india1_SGVS.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.headingContainer {
    display: flex;
    align-items: center;
}



@media only screen and (max-width: 768px) {
    .imageGrid {
        order: -1 !important;
    }

}


@media only screen and (max-width: 400px) {
    .image {
        height: 350px;
    }
}