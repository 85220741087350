.contentContainer {
    display: flex;
}

.textContainer {
    max-width: 300px;

}

@media only screen and (max-width: 968px) {
    .contentContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}



@media only screen and (max-width: 768px) {

    /* .contentContainer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    } */
  

   
}