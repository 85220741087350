.title {
    line-height: 1;
}

.image {
    border-radius: 30% 70% 42% 58% / 30% 21% 79% 70%;
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 500px;
    background-image: url('../../img/About1/ss.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


/* .image{
    width: 100%;
    background-color: red;
    margin: auto;
    height: 600px;
    background-image: url('../../img/ss.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    mask-image: url('../../img/about-img-1.png');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
} */

.headingContainer {
    display: flex;
    align-items: center;
}

.smallLine {
    width: 40px;
    border-bottom: 1px solid rgb(40, 121, 255);
}

.dot {
    color: rgb(82, 89, 188);
}

@media only screen and (max-width: 968px) {
    /* .image{
        width: 100%;
    } */
}

@media only screen and (max-width: 400px) {
    .image {
        height: 350px;
    }
}