.media {
    text-align: center;
    background-image: url(../../img/figure-6.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
}

.mediaData {
    max-width: 500px;
    margin: auto;
    padding: 4rem 2rem;
    cursor: pointer;
}

.mediaData:hover {
    box-shadow: 0px 1px 16px 10px rgba(40, 121, 255, 0.1);
}

.logo {
    margin: auto;
    width: 180px;
    height: 180px;
}

@media only screen and (max-width: 768px) {
    .media {
        background-image: none;
    }
    .mediaData {
        padding: 2rem;
    }
}