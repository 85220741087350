.payLater {
    position: relative;
    background-image: url('../../img/pay_later/team_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.payLaterContent {
    color: #fff;
}

.image {
    border-radius: 30% 70% 42% 58% / 30% 21% 79% 70%;
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 500px;
    background-image: url('../../img/pay_later/uncle_ji.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.headingContainer {
    display: flex;
    align-items: center;
    color: rgb(183, 201, 252);
}

.line {
    border-bottom: 1px solid rgb(183, 201, 252);
}

.check {
    font-size: 1rem !important;
    fill: #fff !important;
    font-weight: 700 !important;
}

.facilities {
    display: flex;
    align-items: center;
}

.roundWhiteSmall {
    position: absolute;
    right: 0px;
    bottom: -145px;
}

@media only screen and (max-width: 400px) {
    .image {
        height: 350px;
    }
}

@media only screen and (max-width: 768px) {
    .roundWhiteSmall {
        display: none;
    }
}